/* tslint:disable */
/* eslint-disable */
/**
 * pb/sr.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApiKeyDescription
 */
export interface ApiKeyDescription {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyDescription
     */
    'keyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyDescription
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyDescription
     */
    'description'?: string;
    /**
     * time the key was created expressed as the number of seconds since Jan 1, 1970 00:00:00 UTC.
     * @type {string}
     * @memberof ApiKeyDescription
     */
    'createTime'?: string;
    /**
     * time the key should expire expressed as the number of seconds since Jan 1, 1970 00:00:00 UTC. A value of 0 indicates the key should never expire.
     * @type {string}
     * @memberof ApiKeyDescription
     */
    'expireTime'?: string;
    /**
     * time the key was last uzed expressed as the number of seconds since Jan 1, 1970 00:00:00 UTC. A value of 0 indicates the key was never used.
     * @type {string}
     * @memberof ApiKeyDescription
     */
    'lastUsed'?: string;
}
/**
 * 
 * @export
 * @interface CreateApiKeyReply
 */
export interface CreateApiKeyReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof CreateApiKeyReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {string}
     * @memberof CreateApiKeyReply
     */
    'keyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiKeyReply
     */
    'keyData'?: string;
}
/**
 * 
 * @export
 * @interface CreateApiKeyRequest
 */
export interface CreateApiKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateApiKeyRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiKeyRequest
     */
    'description'?: string;
    /**
     * time the key should expire expressed as the number of seconds since Jan 1, 1970 00:00:00 UTC. A value of 0 indicates the key should never expire.
     * @type {string}
     * @memberof CreateApiKeyRequest
     */
    'expireTime'?: string;
}
/**
 * 
 * @export
 * @interface CreateDeploymentReply
 */
export interface CreateDeploymentReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof CreateDeploymentReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {string}
     * @memberof CreateDeploymentReply
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CreateDeploymentRequest
 */
export interface CreateDeploymentRequest {
    /**
     * 
     * @type {DeploymentHeader}
     * @memberof CreateDeploymentRequest
     */
    'header'?: DeploymentHeader;
}
/**
 * 
 * @export
 * @interface CreateEnvironmentReply
 */
export interface CreateEnvironmentReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof CreateEnvironmentReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {string}
     * @memberof CreateEnvironmentReply
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CreateEnvironmentRequest
 */
export interface CreateEnvironmentRequest {
    /**
     * 
     * @type {EnvironmentHeader}
     * @memberof CreateEnvironmentRequest
     */
    'header'?: EnvironmentHeader;
}
/**
 * 
 * @export
 * @interface CreateProjectReply
 */
export interface CreateProjectReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof CreateProjectReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectReply
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    /**
     * 
     * @type {ProjectHeader}
     * @memberof CreateProjectRequest
     */
    'header'?: ProjectHeader;
}
/**
 * 
 * @export
 * @interface DatabaseDescription
 */
export interface DatabaseDescription {
    /**
     * 
     * @type {DatabaseHeader}
     * @memberof DatabaseDescription
     */
    'databaseHeader'?: DatabaseHeader;
    /**
     * 
     * @type {Array<DatabaseTableDescription>}
     * @memberof DatabaseDescription
     */
    'tables'?: Array<DatabaseTableDescription>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseDescription
     */
    'serviceNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DatabaseHeader
 */
export interface DatabaseHeader {
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof DatabaseHeader
     */
    'projEnvHeader'?: ProjEnvHeader;
    /**
     * 
     * @type {string}
     * @memberof DatabaseHeader
     */
    'databaseName'?: string;
}
/**
 * 
 * @export
 * @interface DatabaseTableDescription
 */
export interface DatabaseTableDescription {
    /**
     * 
     * @type {string}
     * @memberof DatabaseTableDescription
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTableDescription
     */
    'NumRows'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTableDescription
     */
    'Size'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseTableDescription
     */
    'serviceNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DatastoreDescription
 */
export interface DatastoreDescription {
    /**
     * 
     * @type {DatastoreHeader}
     * @memberof DatastoreDescription
     */
    'datastoreHeader'?: DatastoreHeader;
    /**
     * 
     * @type {string}
     * @memberof DatastoreDescription
     */
    'numObjects'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatastoreDescription
     */
    'capacityConsumedInBytes'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatastoreDescription
     */
    'serviceNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DatastoreHeader
 */
export interface DatastoreHeader {
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof DatastoreHeader
     */
    'projEnvHeader'?: ProjEnvHeader;
    /**
     * 
     * @type {string}
     * @memberof DatastoreHeader
     */
    'datastoreName'?: string;
}
/**
 * 
 * @export
 * @interface DeactivateProjectReply
 */
export interface DeactivateProjectReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DeactivateProjectReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {string}
     * @memberof DeactivateProjectReply
     */
    'deployId'?: string;
}
/**
 * 
 * @export
 * @interface DeactivateProjectRequest
 */
export interface DeactivateProjectRequest {
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof DeactivateProjectRequest
     */
    'projEnvHeader'?: ProjEnvHeader;
}
/**
 * 
 * @export
 * @interface DeleteApiKeyReply
 */
export interface DeleteApiKeyReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DeleteApiKeyReply
     */
    'result'?: ServiceRunnerResult;
}
/**
 * 
 * @export
 * @interface DeleteApiKeyRequest
 */
export interface DeleteApiKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteApiKeyRequest
     */
    'keyId'?: string;
}
/**
 * 
 * @export
 * @interface DeleteEnvironmentReply
 */
export interface DeleteEnvironmentReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DeleteEnvironmentReply
     */
    'result'?: ServiceRunnerResult;
}
/**
 * 
 * @export
 * @interface DeleteEnvironmentRequest
 */
export interface DeleteEnvironmentRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteEnvironmentRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DeletePackageReply
 */
export interface DeletePackageReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DeletePackageReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {PackageState}
     * @memberof DeletePackageReply
     */
    'state'?: PackageState;
}


/**
 * 
 * @export
 * @interface DeletePackageRequest
 */
export interface DeletePackageRequest {
    /**
     * 
     * @type {string}
     * @memberof DeletePackageRequest
     */
    'packageId'?: string;
}
/**
 * 
 * @export
 * @interface DeleteProjectReply
 */
export interface DeleteProjectReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DeleteProjectReply
     */
    'result'?: ServiceRunnerResult;
}
/**
 * 
 * @export
 * @interface DeleteProjectRequest
 */
export interface DeleteProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteProjectRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DeploymentDescription
 */
export interface DeploymentDescription {
    /**
     * 
     * @type {string}
     * @memberof DeploymentDescription
     */
    'id'?: string;
    /**
     * 
     * @type {DeploymentHeader}
     * @memberof DeploymentDescription
     */
    'header'?: DeploymentHeader;
    /**
     * 
     * @type {DeploymentState}
     * @memberof DeploymentDescription
     */
    'state'?: DeploymentState;
    /**
     * 
     * @type {DeploymentStateDetail}
     * @memberof DeploymentDescription
     */
    'stateDetail'?: DeploymentStateDetail;
    /**
     * 
     * @type {string}
     * @memberof DeploymentDescription
     */
    'createTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentDescription
     */
    'validationStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentDescription
     */
    'buildStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentDescription
     */
    'deployStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentDescription
     */
    'endTime'?: string;
}


/**
 * 
 * @export
 * @interface DeploymentHeader
 */
export interface DeploymentHeader {
    /**
     * 
     * @type {string}
     * @memberof DeploymentHeader
     */
    'pkgId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentHeader
     */
    'projId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentHeader
     */
    'envId'?: string;
    /**
     * 
     * @type {DeploymentType}
     * @memberof DeploymentHeader
     */
    'type'?: DeploymentType;
    /**
     * 
     * @type {DeploymentInitiator}
     * @memberof DeploymentHeader
     */
    'initiator'?: DeploymentInitiator;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DeploymentInitiator = {
    InvalidDeployInit: 'INVALID_DEPLOY_INIT',
    Customer: 'CUSTOMER',
    Service: 'SERVICE',
    UnknownDeployInit: 'UNKNOWN_DEPLOY_INIT'
} as const;

export type DeploymentInitiator = typeof DeploymentInitiator[keyof typeof DeploymentInitiator];


/**
 * the package associated with the deployment passed validation and is in  - DEPLOYING: the process of being compiled  the package associated with the deployment compiled successfully and is  - SUCCESS: in the process of being deployed to the environment specified  the deployment was successful and the package associated with the  - FAILED: deployment is now active in the environment specified  the deployment failed; see DeploymentDescription.stateDetail for further  - UNKNOWN_DEPLOY_STATE: MAX_INT
 * @export
 * @enum {string}
 */

export const DeploymentState = {
    InvalidDeployState: 'INVALID_DEPLOY_STATE',
    Created: 'CREATED',
    DplyValidating: 'DPLY_VALIDATING',
    DplyBuilding: 'DPLY_BUILDING',
    Deploying: 'DEPLOYING',
    Success: 'SUCCESS',
    Failed: 'FAILED',
    UnknownDeployState: 'UNKNOWN_DEPLOY_STATE'
} as const;

export type DeploymentState = typeof DeploymentState[keyof typeof DeploymentState];


/**
 * the package associated with the deployment failed to compile  - DPLY_SUPPORT_NEEDED: the deployment failed for an unknown reason; Bopmatic support  - UNKNOWN_DEPLOY_STATE_DET: MAX_INT
 * @export
 * @enum {string}
 */

export const DeploymentStateDetail = {
    InvalidDeployStateDet: 'INVALID_DEPLOY_STATE_DET',
    None: 'NONE',
    PkgInvalid: 'PKG_INVALID',
    BldInvalid: 'BLD_INVALID',
    DplySupportNeeded: 'DPLY_SUPPORT_NEEDED',
    UnknownDeployStateDet: 'UNKNOWN_DEPLOY_STATE_DET'
} as const;

export type DeploymentStateDetail = typeof DeploymentStateDetail[keyof typeof DeploymentStateDetail];


/**
 * the deployment was created in order to remove the project from  - SECURITY_UPDATE: the environment (customer initiated)  the deployment was created in order to perform a security update  - INF_UPDATE: on the backend infrastructure (service initiated)  the deployment was created in order to perform a non-security related  - UNKNOWN_DEPLOY_TYPE: MAX_INT
 * @export
 * @enum {string}
 */

export const DeploymentType = {
    InvalidDeployType: 'INVALID_DEPLOY_TYPE',
    NewPackage: 'NEW_PACKAGE',
    EnvTeardown: 'ENV_TEARDOWN',
    SecurityUpdate: 'SECURITY_UPDATE',
    InfUpdate: 'INF_UPDATE',
    UnknownDeployType: 'UNKNOWN_DEPLOY_TYPE'
} as const;

export type DeploymentType = typeof DeploymentType[keyof typeof DeploymentType];


/**
 * 
 * @export
 * @interface DescribeApiKeyReply
 */
export interface DescribeApiKeyReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DescribeApiKeyReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {ApiKeyDescription}
     * @memberof DescribeApiKeyReply
     */
    'desc'?: ApiKeyDescription;
}
/**
 * 
 * @export
 * @interface DescribeApiKeyRequest
 */
export interface DescribeApiKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DescribeApiKeyRequest
     */
    'keyId'?: string;
}
/**
 * 
 * @export
 * @interface DescribeDatabaseReply
 */
export interface DescribeDatabaseReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DescribeDatabaseReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {DatabaseDescription}
     * @memberof DescribeDatabaseReply
     */
    'desc'?: DatabaseDescription;
}
/**
 * 
 * @export
 * @interface DescribeDatabaseRequest
 */
export interface DescribeDatabaseRequest {
    /**
     * 
     * @type {DatabaseHeader}
     * @memberof DescribeDatabaseRequest
     */
    'databaseHeader'?: DatabaseHeader;
}
/**
 * 
 * @export
 * @interface DescribeDatastoreReply
 */
export interface DescribeDatastoreReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DescribeDatastoreReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {DatastoreDescription}
     * @memberof DescribeDatastoreReply
     */
    'desc'?: DatastoreDescription;
}
/**
 * 
 * @export
 * @interface DescribeDatastoreRequest
 */
export interface DescribeDatastoreRequest {
    /**
     * 
     * @type {DatastoreHeader}
     * @memberof DescribeDatastoreRequest
     */
    'datastoreHeader'?: DatastoreHeader;
}
/**
 * 
 * @export
 * @interface DescribeDeploymentReply
 */
export interface DescribeDeploymentReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DescribeDeploymentReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {DeploymentDescription}
     * @memberof DescribeDeploymentReply
     */
    'desc'?: DeploymentDescription;
}
/**
 * 
 * @export
 * @interface DescribeDeploymentRequest
 */
export interface DescribeDeploymentRequest {
    /**
     * 
     * @type {string}
     * @memberof DescribeDeploymentRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DescribeEnvironmentReply
 */
export interface DescribeEnvironmentReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DescribeEnvironmentReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {EnvironmentDescription}
     * @memberof DescribeEnvironmentReply
     */
    'desc'?: EnvironmentDescription;
}
/**
 * 
 * @export
 * @interface DescribeEnvironmentRequest
 */
export interface DescribeEnvironmentRequest {
    /**
     * 
     * @type {string}
     * @memberof DescribeEnvironmentRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DescribePackageReply
 */
export interface DescribePackageReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DescribePackageReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {PackageDescription}
     * @memberof DescribePackageReply
     */
    'desc'?: PackageDescription;
}
/**
 * 
 * @export
 * @interface DescribePackageRequest
 */
export interface DescribePackageRequest {
    /**
     * 
     * @type {string}
     * @memberof DescribePackageRequest
     */
    'packageId'?: string;
}
/**
 * 
 * @export
 * @interface DescribeProjectReply
 */
export interface DescribeProjectReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DescribeProjectReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {ProjectDescription}
     * @memberof DescribeProjectReply
     */
    'desc'?: ProjectDescription;
}
/**
 * 
 * @export
 * @interface DescribeProjectRequest
 */
export interface DescribeProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof DescribeProjectRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DescribeServiceReply
 */
export interface DescribeServiceReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DescribeServiceReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {ServiceDescription}
     * @memberof DescribeServiceReply
     */
    'desc'?: ServiceDescription;
}
/**
 * 
 * @export
 * @interface DescribeServiceRequest
 */
export interface DescribeServiceRequest {
    /**
     * 
     * @type {ServiceHeader}
     * @memberof DescribeServiceRequest
     */
    'svcHeader'?: ServiceHeader;
}
/**
 * 
 * @export
 * @interface DescribeSiteReply
 */
export interface DescribeSiteReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof DescribeSiteReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof DescribeSiteReply
     */
    'projEnvHeader'?: ProjEnvHeader;
    /**
     * 
     * @type {string}
     * @memberof DescribeSiteReply
     */
    'siteEndpoint'?: string;
}
/**
 * 
 * @export
 * @interface DescribeSiteRequest
 */
export interface DescribeSiteRequest {
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof DescribeSiteRequest
     */
    'projEnvHeader'?: ProjEnvHeader;
}
/**
 * 
 * @export
 * @interface EnvironmentDescription
 */
export interface EnvironmentDescription {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentDescription
     */
    'id'?: string;
    /**
     * 
     * @type {EnvironmentHeader}
     * @memberof EnvironmentDescription
     */
    'header'?: EnvironmentHeader;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentDescription
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnvironmentDescription
     */
    'activeDeployIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnvironmentDescription
     */
    'pendingDeployIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EnvironmentHeader
 */
export interface EnvironmentHeader {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentHeader
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentHeader
     */
    'dnsPrefix'?: string;
}
/**
 * 
 * @export
 * @interface GetLogsEntry
 */
export interface GetLogsEntry {
    /**
     * 
     * @type {string}
     * @memberof GetLogsEntry
     */
    'timestamp'?: string;
    /**
     * log message
     * @type {string}
     * @memberof GetLogsEntry
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetLogsReply
 */
export interface GetLogsReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof GetLogsReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {Array<GetLogsEntry>}
     * @memberof GetLogsReply
     */
    'entries'?: Array<GetLogsEntry>;
}
/**
 * 
 * @export
 * @interface GetLogsRequest
 */
export interface GetLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetLogsRequest
     */
    'projId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLogsRequest
     */
    'envId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLogsRequest
     */
    'serviceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLogsRequest
     */
    'startTime'?: string;
    /**
     * latest log message to retrieve expressed as the number of
     * @type {string}
     * @memberof GetLogsRequest
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface GetMetricSamplesReply
 */
export interface GetMetricSamplesReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof GetMetricSamplesReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {string}
     * @memberof GetMetricSamplesReply
     */
    'metricBuf'?: string;
}
/**
 * 
 * @export
 * @interface GetMetricSamplesRequest
 */
export interface GetMetricSamplesRequest {
    /**
     * 
     * @type {string}
     * @memberof GetMetricSamplesRequest
     */
    'projId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMetricSamplesRequest
     */
    'envId'?: string;
    /**
     * 
     * @type {MetricsScope}
     * @memberof GetMetricSamplesRequest
     */
    'scope'?: MetricsScope;
    /**
     * 
     * @type {string}
     * @memberof GetMetricSamplesRequest
     */
    'scopeQualifier'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMetricSamplesRequest
     */
    'metricNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetMetricSamplesRequest
     */
    'startTime'?: string;
    /**
     * latest metric to retrieve expressed as the number of
     * @type {string}
     * @memberof GetMetricSamplesRequest
     */
    'endTime'?: string;
    /**
     * 
     * @type {MetricsFormat}
     * @memberof GetMetricSamplesRequest
     */
    'format'?: MetricsFormat;
    /**
     * 
     * @type {string}
     * @memberof GetMetricSamplesRequest
     */
    'samplePeriod'?: string;
}


/**
 * 
 * @export
 * @interface GetUploadURLReply
 */
export interface GetUploadURLReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof GetUploadURLReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {string}
     * @memberof GetUploadURLReply
     */
    'URL'?: string;
}
/**
 * 
 * @export
 * @interface GetUploadURLRequest
 */
export interface GetUploadURLRequest {
    /**
     * 
     * @type {string}
     * @memberof GetUploadURLRequest
     */
    'key'?: string;
}
/**
 * 
 * @export
 * @interface ListApiKeysReply
 */
export interface ListApiKeysReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof ListApiKeysReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListApiKeysReply
     */
    'keyIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListDatabasesReply
 */
export interface ListDatabasesReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof ListDatabasesReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof ListDatabasesReply
     */
    'header'?: ProjEnvHeader;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListDatabasesReply
     */
    'databaseNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListDatabasesRequest
 */
export interface ListDatabasesRequest {
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof ListDatabasesRequest
     */
    'header'?: ProjEnvHeader;
}
/**
 * 
 * @export
 * @interface ListDatastoresReply
 */
export interface ListDatastoresReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof ListDatastoresReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof ListDatastoresReply
     */
    'header'?: ProjEnvHeader;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListDatastoresReply
     */
    'datastoreNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListDatastoresRequest
 */
export interface ListDatastoresRequest {
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof ListDatastoresRequest
     */
    'header'?: ProjEnvHeader;
}
/**
 * 
 * @export
 * @interface ListDeploymentsReply
 */
export interface ListDeploymentsReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof ListDeploymentsReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListDeploymentsReply
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListDeploymentsRequest
 */
export interface ListDeploymentsRequest {
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof ListDeploymentsRequest
     */
    'projEnvHeader'?: ProjEnvHeader;
}
/**
 * 
 * @export
 * @interface ListEnvironmentsReply
 */
export interface ListEnvironmentsReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof ListEnvironmentsReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListEnvironmentsReply
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListMetricsEntry
 */
export interface ListMetricsEntry {
    /**
     * 
     * @type {string}
     * @memberof ListMetricsEntry
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMetricsEntry
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMetricsEntry
     */
    'samplePeriod'?: string;
    /**
     * 
     * @type {MetricsScope}
     * @memberof ListMetricsEntry
     */
    'scope'?: MetricsScope;
}


/**
 * 
 * @export
 * @interface ListMetricsReply
 */
export interface ListMetricsReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof ListMetricsReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {Array<ListMetricsEntry>}
     * @memberof ListMetricsReply
     */
    'entries'?: Array<ListMetricsEntry>;
}
/**
 * 
 * @export
 * @interface ListMetricsRequest
 */
export interface ListMetricsRequest {
    /**
     * 
     * @type {MetricsScope}
     * @memberof ListMetricsRequest
     */
    'scope'?: MetricsScope;
}


/**
 * 
 * @export
 * @interface ListPackagesReply
 */
export interface ListPackagesReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof ListPackagesReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {Array<ListPackagesReplyListPackagesItem>}
     * @memberof ListPackagesReply
     */
    'items'?: Array<ListPackagesReplyListPackagesItem>;
}
/**
 * 
 * @export
 * @interface ListPackagesReplyListPackagesItem
 */
export interface ListPackagesReplyListPackagesItem {
    /**
     * 
     * @type {string}
     * @memberof ListPackagesReplyListPackagesItem
     */
    'projId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListPackagesReplyListPackagesItem
     */
    'packageId'?: string;
}
/**
 * 
 * @export
 * @interface ListPackagesRequest
 */
export interface ListPackagesRequest {
    /**
     * 
     * @type {string}
     * @memberof ListPackagesRequest
     */
    'projId'?: string;
}
/**
 * 
 * @export
 * @interface ListProjectsReply
 */
export interface ListProjectsReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof ListProjectsReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListProjectsReply
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListServicesReply
 */
export interface ListServicesReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof ListServicesReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof ListServicesReply
     */
    'header'?: ProjEnvHeader;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListServicesReply
     */
    'serviceNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListServicesRequest
 */
export interface ListServicesRequest {
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof ListServicesRequest
     */
    'header'?: ProjEnvHeader;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MetricsFormat = {
    InvalidMetricFormat: 'INVALID_METRIC_FORMAT',
    MetricFormatOpenmetrics: 'METRIC_FORMAT_OPENMETRICS',
    UnknownMetricFormat: 'UNKNOWN_METRIC_FORMAT'
} as const;

export type MetricsFormat = typeof MetricsFormat[keyof typeof MetricsFormat];


/**
 * 
 * @export
 * @enum {string}
 */

export const MetricsScope = {
    InvalidMetricScope: 'INVALID_METRIC_SCOPE',
    MetricScopeAll: 'METRIC_SCOPE_ALL',
    MetricScopeService: 'METRIC_SCOPE_SERVICE',
    MetricScopeDatastore: 'METRIC_SCOPE_DATASTORE',
    MetricScopeDatabase: 'METRIC_SCOPE_DATABASE',
    UnknownMetricScope: 'UNKNOWN_METRIC_SCOPE'
} as const;

export type MetricsScope = typeof MetricsScope[keyof typeof MetricsScope];


/**
 * 
 * @export
 * @interface PackageDescription
 */
export interface PackageDescription {
    /**
     * 
     * @type {string}
     * @memberof PackageDescription
     */
    'packageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageDescription
     */
    'projId'?: string;
    /**
     * 
     * @type {PackageState}
     * @memberof PackageDescription
     */
    'state'?: PackageState;
    /**
     * 
     * @type {string}
     * @memberof PackageDescription
     */
    'uploadTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageDescription
     */
    'packageSize'?: string;
}


/**
 * the package passed validation and is in the process of being built  - PKG_SUPPORT_NEEDED: something went wrong and Bopmatic support is investigating  - PKG_DELETED: the package has been deleted  - BUILT: the package was successfully built and is eligible to be deployed  - UNKNOWN_PKG_STATE: MAX_INT
 * @export
 * @enum {string}
 */

export const PackageState = {
    InvalidPkgState: 'INVALID_PKG_STATE',
    Uploading: 'UPLOADING',
    Uploaded: 'UPLOADED',
    PkgValidating: 'PKG_VALIDATING',
    Invalid: 'INVALID',
    PkgBuilding: 'PKG_BUILDING',
    PkgSupportNeeded: 'PKG_SUPPORT_NEEDED',
    PkgDeleted: 'PKG_DELETED',
    Built: 'BUILT',
    UnknownPkgState: 'UNKNOWN_PKG_STATE'
} as const;

export type PackageState = typeof PackageState[keyof typeof PackageState];


/**
 * 
 * @export
 * @interface ProjEnvHeader
 */
export interface ProjEnvHeader {
    /**
     * 
     * @type {string}
     * @memberof ProjEnvHeader
     */
    'projId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjEnvHeader
     */
    'envId'?: string;
}
/**
 * 
 * @export
 * @interface ProjectDescription
 */
export interface ProjectDescription {
    /**
     * 
     * @type {string}
     * @memberof ProjectDescription
     */
    'id'?: string;
    /**
     * 
     * @type {ProjectHeader}
     * @memberof ProjectDescription
     */
    'header'?: ProjectHeader;
    /**
     * 
     * @type {ProjectState}
     * @memberof ProjectDescription
     */
    'state'?: ProjectState;
    /**
     * 
     * @type {string}
     * @memberof ProjectDescription
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDescription
     */
    'activeDeployIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDescription
     */
    'pendingDeployIds'?: Array<string>;
}


/**
 * 
 * @export
 * @interface ProjectHeader
 */
export interface ProjectHeader {
    /**
     * 
     * @type {string}
     * @memberof ProjectHeader
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectHeader
     */
    'dnsPrefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectHeader
     */
    'dnsDomain'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectState = {
    InvalidProjState: 'INVALID_PROJ_STATE',
    Inactive: 'INACTIVE',
    Active: 'ACTIVE',
    ProjStateDeleted: 'PROJ_STATE_DELETED',
    UnknownProjState: 'UNKNOWN_PROJ_STATE'
} as const;

export type ProjectState = typeof ProjectState[keyof typeof ProjectState];


/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    'details'?: Array<ProtobufAny>;
}
/**
 * 
 * @export
 * @interface ServiceDescription
 */
export interface ServiceDescription {
    /**
     * 
     * @type {ServiceHeader}
     * @memberof ServiceDescription
     */
    'svcHeader'?: ServiceHeader;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceDescription
     */
    'rpcEndpoints'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceDescription
     */
    'databaseNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceDescription
     */
    'datastoreNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceDescription
     */
    'apiDef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDescription
     */
    'port'?: string;
}
/**
 * 
 * @export
 * @interface ServiceHeader
 */
export interface ServiceHeader {
    /**
     * 
     * @type {ProjEnvHeader}
     * @memberof ServiceHeader
     */
    'projEnvHeader'?: ProjEnvHeader;
    /**
     * 
     * @type {string}
     * @memberof ServiceHeader
     */
    'serviceName'?: string;
}
/**
 * 
 * @export
 * @interface ServiceRunnerResult
 */
export interface ServiceRunnerResult {
    /**
     * 
     * @type {ServiceRunnerStatus}
     * @memberof ServiceRunnerResult
     */
    'status'?: ServiceRunnerStatus;
    /**
     * 
     * @type {string}
     * @memberof ServiceRunnerResult
     */
    'statusDetail'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ServiceRunnerStatus = {
    Invalid: 'STATUS_INVALID',
    Ok: 'STATUS_OK',
    InternalErr: 'STATUS_INTERNAL_ERR',
    DnsConflict: 'STATUS_DNS_CONFLICT',
    AcctLimitReached: 'STATUS_ACCT_LIMIT_REACHED',
    Exists: 'STATUS_EXISTS',
    NotExists: 'STATUS_NOT_EXISTS',
    InvalidRequest: 'STATUS_INVALID_REQUEST',
    Unknown: 'STATUS_UNKNOWN'
} as const;

export type ServiceRunnerStatus = typeof ServiceRunnerStatus[keyof typeof ServiceRunnerStatus];


/**
 * 
 * @export
 * @interface UploadPackageReply
 */
export interface UploadPackageReply {
    /**
     * 
     * @type {ServiceRunnerResult}
     * @memberof UploadPackageReply
     */
    'result'?: ServiceRunnerResult;
    /**
     * 
     * @type {string}
     * @memberof UploadPackageReply
     */
    'pkgId'?: string;
}
/**
 * 
 * @export
 * @interface UploadPackageRequest
 */
export interface UploadPackageRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadPackageRequest
     */
    'projId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadPackageRequest
     */
    'packageXsum'?: string;
    /**
     * package tarball content in .tar.xz format (limited to 6MiB);
     * @type {string}
     * @memberof UploadPackageRequest
     */
    'packageTarballData'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadPackageRequest
     */
    'packageTarballURL'?: string;
}

/**
 * ServiceRunnerApi - axios parameter creator
 * @export
 */
export const ServiceRunnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey: async (body: CreateApiKeyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createApiKey', 'body', body)
            const localVarPath = `/ServiceRunner/CreateApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDeploymentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeployment: async (body: CreateDeploymentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createDeployment', 'body', body)
            const localVarPath = `/ServiceRunner/CreateDeployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEnvironmentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironment: async (body: CreateEnvironmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createEnvironment', 'body', body)
            const localVarPath = `/ServiceRunner/CreateEnvironment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (body: CreateProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createProject', 'body', body)
            const localVarPath = `/ServiceRunner/CreateProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeactivateProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProject: async (body: DeactivateProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deactivateProject', 'body', body)
            const localVarPath = `/ServiceRunner/DeactivateProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey: async (body: DeleteApiKeyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deleteApiKey', 'body', body)
            const localVarPath = `/ServiceRunner/DeleteApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEnvironmentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironment: async (body: DeleteEnvironmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deleteEnvironment', 'body', body)
            const localVarPath = `/ServiceRunner/DeleteEnvironment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePackageRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackage: async (body: DeletePackageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deletePackage', 'body', body)
            const localVarPath = `/ServiceRunner/DeletePackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (body: DeleteProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deleteProject', 'body', body)
            const localVarPath = `/ServiceRunner/DeleteProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DescribeApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeApiKey: async (body: DescribeApiKeyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('describeApiKey', 'body', body)
            const localVarPath = `/ServiceRunner/DescribeApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DescribeDatabaseRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeDatabase: async (body: DescribeDatabaseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('describeDatabase', 'body', body)
            const localVarPath = `/ServiceRunner/DescribeDatabase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DescribeDatastoreRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeDatastore: async (body: DescribeDatastoreRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('describeDatastore', 'body', body)
            const localVarPath = `/ServiceRunner/DescribeDatastore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DescribeDeploymentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeDeployment: async (body: DescribeDeploymentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('describeDeployment', 'body', body)
            const localVarPath = `/ServiceRunner/DescribeDeployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DescribeEnvironmentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeEnvironment: async (body: DescribeEnvironmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('describeEnvironment', 'body', body)
            const localVarPath = `/ServiceRunner/DescribeEnvironment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DescribePackageRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describePackage: async (body: DescribePackageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('describePackage', 'body', body)
            const localVarPath = `/ServiceRunner/DescribePackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DescribeProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeProject: async (body: DescribeProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('describeProject', 'body', body)
            const localVarPath = `/ServiceRunner/DescribeProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DescribeServiceRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeService: async (body: DescribeServiceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('describeService', 'body', body)
            const localVarPath = `/ServiceRunner/DescribeService`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DescribeSiteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeSite: async (body: DescribeSiteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('describeSite', 'body', body)
            const localVarPath = `/ServiceRunner/DescribeSite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs: async (body: GetLogsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getLogs', 'body', body)
            const localVarPath = `/ServiceRunner/GetLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetMetricSamplesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSamples: async (body: GetMetricSamplesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getMetricSamples', 'body', body)
            const localVarPath = `/ServiceRunner/GetMetricSamples`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetUploadURLRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadURL: async (body: GetUploadURLRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getUploadURL', 'body', body)
            const localVarPath = `/ServiceRunner/GetUploadURL`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApiKeys: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listApiKeys', 'body', body)
            const localVarPath = `/ServiceRunner/ListApiKeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListDatabasesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDatabases: async (body: ListDatabasesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listDatabases', 'body', body)
            const localVarPath = `/ServiceRunner/ListDatabases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListDatastoresRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDatastores: async (body: ListDatastoresRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listDatastores', 'body', body)
            const localVarPath = `/ServiceRunner/ListDatastores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListDeploymentsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeployments: async (body: ListDeploymentsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listDeployments', 'body', body)
            const localVarPath = `/ServiceRunner/ListDeployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnvironments: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listEnvironments', 'body', body)
            const localVarPath = `/ServiceRunner/ListEnvironments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListMetricsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMetrics: async (body: ListMetricsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listMetrics', 'body', body)
            const localVarPath = `/ServiceRunner/ListMetrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListPackagesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPackages: async (body: ListPackagesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listPackages', 'body', body)
            const localVarPath = `/ServiceRunner/ListPackages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listProjects', 'body', body)
            const localVarPath = `/ServiceRunner/ListProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListServicesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServices: async (body: ListServicesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listServices', 'body', body)
            const localVarPath = `/ServiceRunner/ListServices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadPackageRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPackage: async (body: UploadPackageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('uploadPackage', 'body', body)
            const localVarPath = `/ServiceRunner/UploadPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceRunnerApi - functional programming interface
 * @export
 */
export const ServiceRunnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceRunnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApiKey(body: CreateApiKeyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateApiKeyReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApiKey(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.createApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateDeploymentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeployment(body: CreateDeploymentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDeploymentReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeployment(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.createDeployment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateEnvironmentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEnvironment(body: CreateEnvironmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateEnvironmentReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEnvironment(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.createEnvironment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(body: CreateProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProjectReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.createProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeactivateProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateProject(body: DeactivateProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeactivateProjectReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateProject(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.deactivateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiKey(body: DeleteApiKeyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteApiKeyReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiKey(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.deleteApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEnvironmentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnvironment(body: DeleteEnvironmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteEnvironmentReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEnvironment(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.deleteEnvironment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeletePackageRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePackage(body: DeletePackageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletePackageReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePackage(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.deletePackage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(body: DeleteProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteProjectReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.deleteProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DescribeApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async describeApiKey(body: DescribeApiKeyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeApiKeyReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.describeApiKey(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.describeApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DescribeDatabaseRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async describeDatabase(body: DescribeDatabaseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeDatabaseReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.describeDatabase(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.describeDatabase']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DescribeDatastoreRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async describeDatastore(body: DescribeDatastoreRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeDatastoreReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.describeDatastore(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.describeDatastore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DescribeDeploymentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async describeDeployment(body: DescribeDeploymentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeDeploymentReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.describeDeployment(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.describeDeployment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DescribeEnvironmentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async describeEnvironment(body: DescribeEnvironmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeEnvironmentReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.describeEnvironment(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.describeEnvironment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DescribePackageRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async describePackage(body: DescribePackageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribePackageReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.describePackage(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.describePackage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DescribeProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async describeProject(body: DescribeProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeProjectReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.describeProject(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.describeProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DescribeServiceRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async describeService(body: DescribeServiceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeServiceReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.describeService(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.describeService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DescribeSiteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async describeSite(body: DescribeSiteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeSiteReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.describeSite(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.describeSite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogs(body: GetLogsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLogsReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogs(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.getLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetMetricSamplesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricSamples(body: GetMetricSamplesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMetricSamplesReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricSamples(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.getMetricSamples']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetUploadURLRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadURL(body: GetUploadURLRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUploadURLReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadURL(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.getUploadURL']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listApiKeys(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListApiKeysReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listApiKeys(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.listApiKeys']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ListDatabasesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDatabases(body: ListDatabasesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatabasesReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDatabases(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.listDatabases']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ListDatastoresRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDatastores(body: ListDatastoresRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatastoresReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDatastores(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.listDatastores']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ListDeploymentsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeployments(body: ListDeploymentsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDeploymentsReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeployments(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.listDeployments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEnvironments(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEnvironmentsReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEnvironments(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.listEnvironments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ListMetricsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMetrics(body: ListMetricsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMetricsReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMetrics(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.listMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ListPackagesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPackages(body: ListPackagesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPackagesReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPackages(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.listPackages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjects(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListProjectsReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjects(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.listProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ListServicesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServices(body: ListServicesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServicesReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServices(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.listServices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UploadPackageRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPackage(body: UploadPackageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadPackageReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPackage(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceRunnerApi.uploadPackage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServiceRunnerApi - factory interface
 * @export
 */
export const ServiceRunnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceRunnerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: CreateApiKeyRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateApiKeyReply> {
            return localVarFp.createApiKey(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDeploymentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeployment(body: CreateDeploymentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateDeploymentReply> {
            return localVarFp.createDeployment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEnvironmentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironment(body: CreateEnvironmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateEnvironmentReply> {
            return localVarFp.createEnvironment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(body: CreateProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateProjectReply> {
            return localVarFp.createProject(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeactivateProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProject(body: DeactivateProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeactivateProjectReply> {
            return localVarFp.deactivateProject(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(body: DeleteApiKeyRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteApiKeyReply> {
            return localVarFp.deleteApiKey(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEnvironmentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironment(body: DeleteEnvironmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteEnvironmentReply> {
            return localVarFp.deleteEnvironment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePackageRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackage(body: DeletePackageRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeletePackageReply> {
            return localVarFp.deletePackage(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(body: DeleteProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteProjectReply> {
            return localVarFp.deleteProject(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DescribeApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeApiKey(body: DescribeApiKeyRequest, options?: RawAxiosRequestConfig): AxiosPromise<DescribeApiKeyReply> {
            return localVarFp.describeApiKey(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DescribeDatabaseRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeDatabase(body: DescribeDatabaseRequest, options?: RawAxiosRequestConfig): AxiosPromise<DescribeDatabaseReply> {
            return localVarFp.describeDatabase(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DescribeDatastoreRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeDatastore(body: DescribeDatastoreRequest, options?: RawAxiosRequestConfig): AxiosPromise<DescribeDatastoreReply> {
            return localVarFp.describeDatastore(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DescribeDeploymentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeDeployment(body: DescribeDeploymentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DescribeDeploymentReply> {
            return localVarFp.describeDeployment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DescribeEnvironmentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeEnvironment(body: DescribeEnvironmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DescribeEnvironmentReply> {
            return localVarFp.describeEnvironment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DescribePackageRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describePackage(body: DescribePackageRequest, options?: RawAxiosRequestConfig): AxiosPromise<DescribePackageReply> {
            return localVarFp.describePackage(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DescribeProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeProject(body: DescribeProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<DescribeProjectReply> {
            return localVarFp.describeProject(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DescribeServiceRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeService(body: DescribeServiceRequest, options?: RawAxiosRequestConfig): AxiosPromise<DescribeServiceReply> {
            return localVarFp.describeService(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DescribeSiteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeSite(body: DescribeSiteRequest, options?: RawAxiosRequestConfig): AxiosPromise<DescribeSiteReply> {
            return localVarFp.describeSite(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(body: GetLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetLogsReply> {
            return localVarFp.getLogs(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetMetricSamplesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSamples(body: GetMetricSamplesRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetMetricSamplesReply> {
            return localVarFp.getMetricSamples(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetUploadURLRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadURL(body: GetUploadURLRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetUploadURLReply> {
            return localVarFp.getUploadURL(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApiKeys(body: object, options?: RawAxiosRequestConfig): AxiosPromise<ListApiKeysReply> {
            return localVarFp.listApiKeys(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListDatabasesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDatabases(body: ListDatabasesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListDatabasesReply> {
            return localVarFp.listDatabases(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListDatastoresRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDatastores(body: ListDatastoresRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListDatastoresReply> {
            return localVarFp.listDatastores(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListDeploymentsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeployments(body: ListDeploymentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListDeploymentsReply> {
            return localVarFp.listDeployments(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnvironments(body: object, options?: RawAxiosRequestConfig): AxiosPromise<ListEnvironmentsReply> {
            return localVarFp.listEnvironments(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListMetricsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMetrics(body: ListMetricsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListMetricsReply> {
            return localVarFp.listMetrics(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListPackagesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPackages(body: ListPackagesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListPackagesReply> {
            return localVarFp.listPackages(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(body: object, options?: RawAxiosRequestConfig): AxiosPromise<ListProjectsReply> {
            return localVarFp.listProjects(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListServicesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServices(body: ListServicesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListServicesReply> {
            return localVarFp.listServices(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadPackageRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPackage(body: UploadPackageRequest, options?: RawAxiosRequestConfig): AxiosPromise<UploadPackageReply> {
            return localVarFp.uploadPackage(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceRunnerApi - object-oriented interface
 * @export
 * @class ServiceRunnerApi
 * @extends {BaseAPI}
 */
export class ServiceRunnerApi extends BaseAPI {
    /**
     * 
     * @param {CreateApiKeyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public createApiKey(body: CreateApiKeyRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).createApiKey(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDeploymentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public createDeployment(body: CreateDeploymentRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).createDeployment(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEnvironmentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public createEnvironment(body: CreateEnvironmentRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).createEnvironment(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProjectRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public createProject(body: CreateProjectRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).createProject(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeactivateProjectRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public deactivateProject(body: DeactivateProjectRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).deactivateProject(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteApiKeyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public deleteApiKey(body: DeleteApiKeyRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).deleteApiKey(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEnvironmentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public deleteEnvironment(body: DeleteEnvironmentRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).deleteEnvironment(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePackageRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public deletePackage(body: DeletePackageRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).deletePackage(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteProjectRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public deleteProject(body: DeleteProjectRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).deleteProject(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DescribeApiKeyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public describeApiKey(body: DescribeApiKeyRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).describeApiKey(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DescribeDatabaseRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public describeDatabase(body: DescribeDatabaseRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).describeDatabase(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DescribeDatastoreRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public describeDatastore(body: DescribeDatastoreRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).describeDatastore(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DescribeDeploymentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public describeDeployment(body: DescribeDeploymentRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).describeDeployment(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DescribeEnvironmentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public describeEnvironment(body: DescribeEnvironmentRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).describeEnvironment(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DescribePackageRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public describePackage(body: DescribePackageRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).describePackage(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DescribeProjectRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public describeProject(body: DescribeProjectRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).describeProject(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DescribeServiceRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public describeService(body: DescribeServiceRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).describeService(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DescribeSiteRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public describeSite(body: DescribeSiteRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).describeSite(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetLogsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public getLogs(body: GetLogsRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).getLogs(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetMetricSamplesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public getMetricSamples(body: GetMetricSamplesRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).getMetricSamples(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetUploadURLRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public getUploadURL(body: GetUploadURLRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).getUploadURL(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public listApiKeys(body: object, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).listApiKeys(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListDatabasesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public listDatabases(body: ListDatabasesRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).listDatabases(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListDatastoresRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public listDatastores(body: ListDatastoresRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).listDatastores(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListDeploymentsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public listDeployments(body: ListDeploymentsRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).listDeployments(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public listEnvironments(body: object, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).listEnvironments(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListMetricsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public listMetrics(body: ListMetricsRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).listMetrics(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListPackagesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public listPackages(body: ListPackagesRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).listPackages(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public listProjects(body: object, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).listProjects(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListServicesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public listServices(body: ListServicesRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).listServices(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadPackageRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRunnerApi
     */
    public uploadPackage(body: UploadPackageRequest, options?: RawAxiosRequestConfig) {
        return ServiceRunnerApiFp(this.configuration).uploadPackage(body, options).then((request) => request(this.axios, this.basePath));
    }
}



