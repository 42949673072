import React from 'react';

const AccessTokens: React.FC = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Access Tokens Page</h1>
      <p>Coming soon</p>
    </div>
  );
};

export default AccessTokens;
