import React from 'react';

const Metrics: React.FC = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Metrics Page</h1>
      <p>Coming soon</p>
    </div>
  );
};

export default Metrics;
